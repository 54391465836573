/*! 2025-4-2 20:17:05 */
[data-v-d22db8e0]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.popover-title[data-v-d22db8e0]{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:13px;margin-left:5px;position:absolute;bottom:10px;right:10px}.popover-title[data-v-d22db8e0]:hover{cursor:pointer}.green-box[data-v-d22db8e0]{background:rgba(112,182,3,0.2);color:#70b603}.origin-box[data-v-d22db8e0]{background:rgba(245,154,35,0.2);color:#f59a23}

[data-v-64b1512b]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.split-products-shipment .split-products-shipment-row[data-v-64b1512b]{margin:0}.split-products-shipment .split-products-shipment-row .el-col[data-v-64b1512b]{margin-bottom:20px}.split-products-shipment .add-btn[data-v-64b1512b]{padding:10px;text-align:center}.split-products-shipment .add-btn span[data-v-64b1512b]{color:#1890FF;text-decoration:underline;cursor:pointer}.split-products-shipment[data-v-64b1512b]  .el-form-item{margin:14px 0}.split-products-shipment[data-v-64b1512b]  .el-form-item .el-date-editor{width:100%}

[data-v-53d701fe]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}[data-v-53d701fe] .el-table__expanded-cell{padding:0 !important}[data-v-53d701fe] .el-table__body tr{min-height:0px !important}[data-v-53d701fe] .shipped-flag .el-checkbox.is-disabled .el-checkbox__inner{background-color:#f2f2f2 !important;border-color:#f2f2f2 !important}.jz[data-v-53d701fe]{display:flex;align-items:center;justify-content:center}.el-tag-other[data-v-53d701fe]{position:absolute;right:4px;z-index:2;background-color:#fff7e8;border-color:#ffefd1;color:#ffae18}[data-v-53d701fe] .query-dialog .el-dialog__header{border-bottom:1px solid #c0c4cc}[data-v-53d701fe] .query-dialog .el-dialog__body{min-height:400px;max-height:600px;overflow-y:auto}[data-v-53d701fe] .query-dialog .el-dialog__footer{padding:20px;border-top:1px solid #c0c4cc}.text-d7[data-v-53d701fe]{color:#999 !important}

[data-v-5eeaa1d2]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.log-wrapper[data-v-5eeaa1d2]{padding:5px 15px 15px 15px}.log-wrapper .list-item[data-v-5eeaa1d2]{margin-bottom:10px;line-height:26px;padding-left:5px}

[data-v-fdbea00e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.operationContent[data-v-fdbea00e]{width:100%;overflow:hidden}.operationContent span[data-v-fdbea00e]{width:calc(100% - 20px);display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.text-ellipsis[data-v-fdbea00e]{-webkit-line-clamp:3;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}[data-v-fdbea00e] .el-table__header,[data-v-fdbea00e] .el-table__body{border-left:1px solid #ebeef5}

[data-v-663fcd00]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.custom-wrap .base-info[data-v-663fcd00]{height:calc(100% - 44px);overflow-y:auto}.custom-wrap .tag-wrap[data-v-663fcd00]{position:relative;display:flex;justify-content:center}.custom-wrap .tag-wrap .el-tag[data-v-663fcd00]{position:absolute;top:-10px;right:0;z-index:2}.custom-wrap .tag-wrap .el-image[data-v-663fcd00]{width:50px;height:50px;border-radius:2px}.title[data-v-663fcd00]{font-weight:bold;font-size:14px;font-family:'PingFang-Bold';margin-right:10px}


/*# sourceMappingURL=chunk-c1d3154a.9d986315.css.map*/